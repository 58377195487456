
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { Contact } from "@/models/cases/Contact";

@Component({})
export default class GdprReport extends Vue {
    @Prop({ type: Object, required: true })
    public readonly contact!: Contact;

    getDate(): string {
        const dateNow = new Date();
        return `${dateNow.getDate()}-${
            dateNow.getMonth() + 1
        }-${dateNow.getFullYear()}`;
    }
}
