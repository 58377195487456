
import { Component } from "vue-property-decorator";
import Vue from "vue";
import DynamicTitle from "@/components/DynamicTitle";
import { Routes } from "@/router/routes";
import { TitleElement } from "@/components/DynamicTitle/TitleElement";
import { contactModule } from "@/store/modules/contact";
import { ContactGetters } from "@/store/modules/contact/getters";
import { PaginationResult } from "@/models/PaginationResult";
import { ContactListDto } from "@/models/Gdpr/ContactListDto";
import { CaseForContactDTO } from "@/models/Gdpr/CaseForContactDTO";
import { module as casesModule } from "@/store/modules/cases";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import { PaginationInfo } from "@/models/PaginationInfo";
import { ContactFilter } from "@/models/Gdpr/ContactFilter";
import { ContactActions } from "@/store/modules/contact/actions";
import PopupReport from "@/components/PopupReport";
import { PrintFromHtmlA4 } from "@/services/pdfGenerator";
import { Contact } from "@/models/cases/Contact";
import { ContactMutation } from "@/store/modules/contact/mutations";
import GdprReport from "@/components/GdprReport";
import { userHasClaims } from "@/services/userUtils";
import { DELETE_PERMISSION } from "@/consts/customClaims";
import { CasesActions } from "@/store/modules/cases/actions";

@Component({
    components: {
        DynamicTitle,
        ConfirmationDialog,
        IframeReport: PopupReport,
        GdprReport
    },
    computed: {
        ...contactModule.mapGetters({
            paginationData: ContactGetters.PaginationData,
            isLoading: ContactGetters.IsLoading,
            contact: ContactGetters.Contact
        })
    },
    methods: {
        ...contactModule.mapActions({
            loadContactsWithPagination:
                ContactActions.LoadContactsWithPagination,
            getContactById: ContactActions.LoadContactById
        }),
        ...contactModule.mapMutations({
            setContact: ContactMutation.SetContact
        }),
        ...casesModule.mapActions({
            deleteCase: CasesActions.DeleteCase
        })
    }
})
export default class GdprEdit extends Vue {
    private readonly loadContactsWithPagination!: (
        payload: PaginationInfo & ContactFilter
    ) => Promise<void>;
    private readonly getContactById!: (contactId: number) => Promise<Contact>;
    private name = "";
    private readonly deleteCase!: (caseId: number) => Promise<void>;

    $refs!: {
        reportPdf: GdprReport;
    };

    protected readonly titleElements: TitleElement[] = [
        { name: "GDPR Search", selected: true, link: Routes.GdprSearch },
        { name: "Contacts", selected: true }
    ];
    private contact!: Contact | null;
    protected readonly paginationData!: PaginationResult<ContactListDto>;
    protected readonly isLoading!: boolean;
    protected items: CaseForContactDTO[] = [];
    private selectedItemId: number | null = null;
    protected deleteDialog = false;
    protected showReport = false;

    protected headers = [
        { text: "Id", value: "id", sortable: false, filterable: false },
        { text: "Title", value: "title", sortable: false, filterable: false },
        {
            text: "Erstellt",
            value: "createdDateTime",
            sortable: false,
            filterable: false
        },
        {
            text: "Bearbeitet",
            value: "lastModifiedDateTime",
            sortable: false,
            filterable: false
        },
        {
            text: "Actions",
            value: "actions",
            sortable: false,
            filterable: false
        }
    ];

    created(): void {
        if (this.paginationData.items.length === 0 || !this.contact)
            this.getDataFromApi();
        else this.setItems();
    }
    getDataFromApi(): void {
        this.loadContactsWithPagination({
            take: 20,
            skip: 0
        }).then(() => this.setItems());
        this.getContactById(Number.parseInt(this.$route.params.id));
    }
    setItems(): void {
        this.items = this.paginationData.items
            .filter((item) => item.id.toString() == this.$route.params.id)
            .flatMap((item) => {
                this.name = item.name;
                return item.cases;
            });
    }

    editItem(caseModel: CaseForContactDTO): void {
        if (caseModel.id) {
            this.$router.push(Routes.CaseEdit + caseModel.id);
        }
    }

    deleteItem(caseModel: CaseForContactDTO): void {
        this.selectedItemId = caseModel.id;
        this.deleteDialog = true;
    }

    confirmDeleteCase(): void {
        if (this.selectedItemId) {
            this.deleteCase(this.selectedItemId);
        }
    }

    getFormattedDateString(dateString: string, showTime?: boolean): string {
        if (!showTime) {
            return new Date(dateString).toLocaleDateString("de-DE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
            });
        } else {
            return new Date(dateString).toLocaleString("de-DE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                second: "2-digit",
                hour: "2-digit",
                minute: "2-digit"
            });
        }
    }

    downloadPdf(): void {
        if (!this.contact) this.getDataFromApi();
        const date = new Date();
        PrintFromHtmlA4(
            this.$refs.reportPdf.$el.innerHTML,
            `${this.name.replace(" ", "_")}_${date.getDate()}-${
                date.getMonth() + 1
            }-${date.getFullYear()}`,
            20
        );
    }

    showPdf(): void {
        if (!this.contact) this.getDataFromApi();
        this.showReport = !this.showReport;
    }

    get isAllowedToDelete(): boolean {
        return userHasClaims([DELETE_PERMISSION]);
    }
}
