import jsPDF, { jsPDFOptions } from "jspdf";

const widthA4 = 595.28;
const heightA4 = 841.89;

export const a4Options: jsPDFOptions = {
    unit: "pt",
    format: [widthA4, heightA4]
};

export function PrintFromHtmlA4(
    src: string | HTMLElement,
    filename: string,
    margin = 0
): void {
    const doc = new jsPDF({ ...a4Options, orientation: "portrait" });
    if (src) {
        doc.html(src, {
            autoPaging: "text",
            margin: [margin, margin, margin, margin],
            width: widthA4 - 2 * margin,
            windowWidth: widthA4 - 2 * margin
        }).then(() => doc.save(filename));
    }
}
